import React, { useState, useEffect } from "react";
import TinderCard from "react-tinder-card";
import "./TinderCards.css";
import database from "./firebase";
import { getFirestore, collection, getDocs, getDoc, doc, query, where, Timestamp, addDoc, orderBy, limit, setDoc, onSnapshot } from "firebase/firestore"

function TinderCards() {
  const [people, setPeople] = useState([]);

  useEffect(() => {

      const q = query(collection(database, "people"))
      const unsubscribe = onSnapshot(q, (snapshot) => {
        setPeople(snapshot.docs.map((doc) => doc.data()))
      });

    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <div>
      <div className="tinderCards__cardContainer">
        {people.map((person) => (
          <TinderCard
            className="swipe"
            key={person.name}
            preventSwipe={["up", "down"]}
          >
            <div
              style={{ backgroundImage: `url(${person.url})` }}
              className="card"
            >
              <h3>{person.name}</h3>
            </div>
          </TinderCard>
        ))}
      </div>
    </div>
  );
}

export default TinderCards;
