import React from "react";
import logo from './assets/logo.png'
import { Link, useNavigate } from "react-router-dom";
import { ArrowBackIos, Forum, Person } from "@mui/icons-material";
import { IconButton } from "@mui/material";

function Header({ backButton }) {
  const navigate = useNavigate();
  return (
    <div className="flex justify-between items-center border-b border-gray-100 px-4 py-2">
      {backButton ? (
        <IconButton onClick={() => navigate(backButton,{replace: true})}>
          <ArrowBackIos className="" fontSize="large" />
        </IconButton>
      ) : (
        <IconButton>
          <Person className="" fontSize="large" />
        </IconButton>
      )}
      <Link to="/">
        <img
          className=" h-8 object-contain"
          src={logo}
          alt="corporatelinkup"
        />
      </Link>
      {!backButton && <Link to="/chat">
        <IconButton>
          <Forum className="" fontSize="large" />
        </IconButton>
      </Link>}
    </div>
  );
}

export default Header;
