import { initializeApp } from 'firebase/app';
import { getFirestore } from "firebase/firestore"

const firebaseConfig = {
    apiKey: "AIzaSyCZAiaT814XtkieQcgfnXV9WtDa_F4da9Q",
    authDomain: "corporatelinkup-f785a.firebaseapp.com",
    projectId: "corporatelinkup-f785a",
    storageBucket: "corporatelinkup-f785a.appspot.com",
    messagingSenderId: "670733957473",
    appId: "1:670733957473:web:bbed027707dd7ca7e0ed20",
    measurementId: "G-QVMYQZS1R5"
};

const firebaseApp = initializeApp (firebaseConfig);

const database = getFirestore(firebaseApp);

export default database;