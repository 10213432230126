import React, { useState } from 'react'
import Card from './Card'
import { useMotionValue, useTransform, useMotionTemplate } from 'framer-motion';

const colors = ['#FFBE0B', '#FB5607', '#FF006E', '#8338EC', '#3A86FF'];


const Home = () => {

    const [cards, setCards] = useState([
        { text: 'Up or Down', background: colors[0], content: {} },
        { text: 'Left or Right', background: colors[1], content: {} },
        { text: 'Swipe Me', background: colors[2], content: {} }
    ]);

    const [dragStart, setDragStart] = useState({
        axis: null,
        animation: { x: 0, y: 0 }
    });

    const x = useMotionValue(0);
    const y = useMotionValue(0);
    const scale = useTransform(dragStart.axis === 'x' ? x : y, [-500, 0, 500], [1, .8, 1]);
    const shadowBlur = useTransform(dragStart.axis === 'x' ? x : y, [-200, 0, 200], [0, 25, 0]);
    const shadowOpacity = useTransform(dragStart.axis === 'x' ? x : y, [-200, 0, 200], [0, .2, 0]);
    const boxShadow = useMotionTemplate`0 ${shadowBlur}px 25px -5px rgba(0, 0, 0, ${shadowOpacity})`;


    return (
        <div className="relative grid w-full flex-1 place-items-center bg-slate-500 overflow-hidden">
            {cards.map((card, index) => {
                if (index === cards.length - 1) {
                    return (
                        <Card
                            content={card.content}
                            card={card}
                            key={index}
                            style={{ x, y, zIndex: index }}
                            cards={cards}
                            dragStart={dragStart}
                            setCards={setCards}
                            x={x}
                            y={y}
                            setDragStart={setDragStart}
                            colors={colors}
                        />
                    )
                } else {

                    return (
                        <Card
                            content={card.content}
                            card={card}
                            key={index}
                            style={{
                                scale,
                                boxShadow,
                                zIndex: index
                            }}
                        />
                    )
                }
            })}
        </div>
    )
}
export default Home