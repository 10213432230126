import React from "react";
import { createBrowserRouter, Route, RouterProvider, Routes } from 'react-router-dom';
import Header from "./Header";
import SwipeButtons from "./SwipeButtons";
import Chats from "./Chats";
import TinderCards from "./TinderCards";
import ChatScreen from "./ChatScreen";
import Home from "./Home";

function Root() {
  return (
    <div className="h-full">
      <Routes>
        <Route path="/chat/:person" element={<><Header backButton="/chat" /><ChatScreen /></>} />
        <Route path="/chat" element={<><Header backButton="/" /><Chats /></>} />
        <Route path="/" element={<div className="flex flex-col h-full justify-center"><Header /><Home /></div>} />
        {/* <Route path="/" element={<Home />} /> */}
      </Routes>
    </div>
  );
}

const router = createBrowserRouter([
  { path: "*", element: <Root /> }
])


export default function App() {
  return (
    <RouterProvider router={router} />
  )
}
