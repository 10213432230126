import React from 'react'
import { motion } from 'framer-motion';
import CardBody from './CardBody';


const Card = ({content, card, style, dragStart, setDragStart, x, y, cards, setCards, colors }) => {

    const onDirectionLock = axis => {
        //only drag in y
        axis === 'y' && setDragStart({ ...dragStart, axis: axis })
        // console.log(dragStart)
    }
    const randomColor = current => {
        while (true) {
            const index = Math.floor(Math.random() * colors.length);
            if (current !== colors[index]) {
                return colors[index];
            }
        }
    }

    const animateCardSwipe = animation => {
        setDragStart({ ...dragStart, animation })
        setTimeout(() => {
            setCards([{
                text: 'just an another card',
                background: randomColor(cards[0].background)
            }, ...cards.slice(0, cards.length - 1)]);
            x.set(0);
            y.set(0);
            setDragStart({ axis: null, animation: { x: 0, y: 0 } });
        }, 100)
    }

    const onDragEnd = info => {
        const offset =info.offset.y
            if (offset >= 10 && offset <= 200){
                animateCardSwipe({ x: 0, y: 500 });
            }
            else if (offset <= -10 && offset >= -200){
                animateCardSwipe({ x: 0, y: -500 });
            }
    } 
    const onDrag = info => {
        let box = document.querySelector('#card');
        let height = box.offsetHeight;
        let f = 0.9
        if (dragStart.axis === 'x') {
            if (info.offset.x >= 100)
                animateCardSwipe({ x: 200, y: 0 });
            else if (info.offset.x <= -100)
                animateCardSwipe({ x: -200, y: 0 });
        } else {
            console.log(info.offset)
            if (info.offset.y >= f*height){
                animateCardSwipe({ x: 0, y: 500 });
            }
            else if (info.offset.y <= -f*height){
                animateCardSwipe({ x: 0, y: -500 });
            }
        }
    }

    return (
        <motion.div
        id='card'
            className="absolute left-0 top-0 right-0 bottom-0 h-[95%] w-[90%] rounded-md p-10 place-items-center grid m-auto"
            drag="y"
            dragConstraints={{ left: 0, right: 0, top: 0, bottom: 0 }}
            dragDirectionLock
            onDirectionLock={axis => onDirectionLock(axis)}
            onDrag={
                (event, info) => onDrag(info)
              }
            onDragEnd={(e, info) => onDragEnd(info)}
            animate={dragStart?.animation}
            style={{ ...style, background: card.background }}
            transition={{ ease: [.6, .05, -.01, .9] }}
            dragElastic = {{top: 0, bottom: 0}}
            dragMomentum={false}
            animation={false}
            dragTransition={{ bounceStiffness: 0, bounceDamping: 0 }}
        // whileTap={{ scale: .85 }}
        >
            <CardBody content={content}/>
        </motion.div>
    )
}

export default Card